import {
    NavLink,
} from "react-router-dom";

import logo from "../assets/img/logo-small.svg";
import Icons from "../assets/icons/icons.js";
export default function Sidebar() {
    return (
        <div id="sidebar">
            <div class="top">
                <button onClick={() => document.getElementById("sidebar").classList.toggle("open")} className="hamburger-button"><Icons.HamburgerMenu /></button>
                <img src={logo} className="app-logo" alt="logo" />
                <ul>
                    <li>
                        <NavLink to="/" onClick={() => document.getElementById("sidebar").classList.toggle("open")}>
                            <Icons.Collections /><span>Collecties</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/aanvragen" onClick={() => document.getElementById("sidebar").classList.toggle("open")}>
                            <Icons.Requests /><span>Ontvangen aanvragen</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div class="bottom">
                <ul>
                    <li>
                        <a href="mailto:help@example.com"><Icons.Help /><span>Help</span></a>
                    </li>
                </ul>
                <div className="AccountInfo">
                    <img className="avatar" src="/avatar.jpeg" alt="Avatar" />
                    <span className="name">Gideon Swaak</span>
                    <span className="email">gideon.swaak@student.hu.nl</span>
                    <Icons.Settings className="gear" />
                </div>
            </div>
        </div>
    );
}