import React, { useEffect, useState } from "react";
import Icons from "../assets/icons/icons.js";
import CollectionCard from "../components/collection-card.js";

export default function Collections() {
	const [collections, setCollections] = useState([]);

	const fetchCollections = async () => {
		fetch("/api/collections")
			.then((response) => response.json())
			.then((data) => setCollections(data));
	}

	useEffect(() => {
		fetchCollections();
	}, []);


	return (
		<main>
			<div className="top-bar">
				<h2><Icons.Collections />Collecties</h2>
				<button className={"btn add-collection"}>
					<Icons.Plus />
					<span>Voeg collectie toe</span>
				</button>
			</div>
			<div className="content">
				<div className="collection-card-wrapper">
					{collections.map((collection) => (
						<CollectionCard
							image={collection.image}
							title={collection.title}
							numberOfGoals={collection.goalCount}
							numberOfEvidences={collection.evidenceCount}
						/>
					))}
				</div>
			</div>
		</main>
	);
}