import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Icons from "../assets/icons/icons";

export default function Aanvragen() {
	const [aanvragen, setAanvragen] = useState([]);
	const [closed, setClosed] = useState(false);
	const [sort, setSort] = useState({ by: "datetime", order: "desc" });
	
	const fetchAanvragen = async () => {
		fetch("/api/requests")
			.then((response) => response.json())
			.then((data) => setAanvragen(data));
	}

	useEffect(() => {
		fetchAanvragen();
	}, []);

	const dateFormatOptions = {
		month: "short",
		day: "numeric",
		minute: "numeric",
		hour: "numeric"
	};

	return (
		<main>
			<div className="top-bar">
				<h2><Icons.Requests />Aanvragen</h2>
			</div>

			<div className="tabs">
				<button className={classNames({ tab: true, active: !closed })} onClick={() => setClosed(false)}>Openstaand</button>
				<button className={classNames({ tab: true, active: closed })} onClick={() => setClosed(true)}>Afgerond</button>
			</div>
			
			<table className={"aanvragen"}>
				<thead>
					<tr>
						<th></th>
						<th onClick={() => setSort({ by: "type", order: sort.by === "type" && sort.order === "asc" ? "desc" : "asc" })} className={classNames({ type: true, activesort: sort.by === "type" })}>Type aanvraag<Icons.SortArrow direction={sort.order} /></th>
						<th onClick={() => setSort({ by: "requester", order: sort.by === "requester" && sort.order === "asc" ? "desc" : "asc" })} className={classNames({ requester: true, activesort: sort.by === "requester" })}>Aanvrager<Icons.SortArrow direction={sort.order} /></th>
						<th onClick={() => setSort({ by: "datetime", order: sort.by === "datetime" && sort.order === "asc" ? "desc" : "asc" })} className={classNames({ datetime: true, activesort: sort.by === "datetime" })}>Datum/tijdstip<Icons.SortArrow direction={sort.order} /></th>
					</tr>
				</thead>
				<tbody>
					{aanvragen.filter(aanvraag => aanvraag.closed === closed)
						.sort((a, b) => {
							if (sort.order === "asc") {
								return a[sort.by] > b[sort.by] ? 1 : -1;
							} else {
								return a[sort.by] < b[sort.by] ? 1 : -1;
							}
						})
						.map(aanvraag => (
							<tr className={"aanvraag"}>
								<td className={"checkmark"}><Icons.Checkmark checked={closed} /></td>
								<td className={"type"}>{aanvraag.type}</td>
								<td className={"requester"}><Icons.Person />{aanvraag.requester}</td>
								<td className={"datetime"}><Icons.Date />{new Date(aanvraag.datetime).toLocaleString("NL-nl", dateFormatOptions)}</td>
							</tr>
						))}
				</tbody>
			</table>
		</main>
	);
}