import Icons from "../assets/icons/icons";

function CollectionCard({ image, title, numberOfGoals, numberOfEvidences }) {
    return (
        <div className="collection-card">
            <img src={image} alt="collection" />
            <span className="title">{title}</span>
            <div className="stats">
                <Icons.Goals /> <span>{numberOfGoals} doelen</span>
                <Icons.Evidences /> <span>{numberOfEvidences} bewijzen</span>
            </div>
        </div>
    );
}

export default CollectionCard;