import React from 'react';

function Requests() {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 11a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v3a3 3 0 0 1-3 3H9" stroke="#696974" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4 11c1 0 1 1.5 1 2.756C5 15 9 12.837 9 11" stroke="#696974" stroke-width="2"></path></svg>
	);
}

function Collections({ size = 14 }) {
	return (
		<svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 2.286C2 2.128 2.128 2 2.286 2h3.062c.108 0 .207.061.256.158l.635 1.27H2V2.286zm0 3.142v5C2 11.297 2.704 12 3.571 12h6.858c.867 0 1.571-.704 1.571-1.571V5.714a.286.286 0 0 0-.286-.285H8.652a2.29 2.29 0 0 1-.152-.005v.004H2zM2.286 0A2.286 2.286 0 0 0 0 2.286V10.429A3.571 3.571 0 0 0 3.571 14h6.858A3.571 3.571 0 0 0 14 10.429V5.714a2.286 2.286 0 0 0-2.286-2.285H8.652a.286.286 0 0 1-.256-.158L7.393 1.264A2.286 2.286 0 0 0 5.348 0H2.286z" fill="#696974"></path></svg>
	);
}

function Help() {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><circle cx="8" cy="8" r="7" stroke="#696974" stroke-width="2"></circle><path d="M7.11 9.55v-.16c0-.442.036-.795.109-1.058.075-.263.185-.474.328-.633.143-.161.318-.308.523-.441.164-.104.31-.21.438-.317.13-.109.233-.226.308-.351a.807.807 0 0 0 .114-.426.718.718 0 0 0-.41-.676.891.891 0 0 0-.418-.097.885.885 0 0 0-.762.406.855.855 0 0 0-.13.465H5.415c.008-.547.134-.995.379-1.344.247-.349.574-.607.98-.773a3.46 3.46 0 0 1 1.344-.254c.537 0 1.013.083 1.43.25.42.166.749.412.988.738.24.325.36.724.36 1.195 0 .31-.053.584-.157.82-.104.238-.25.447-.437.63a3.48 3.48 0 0 1-.653.492 2.608 2.608 0 0 0-.488.359 1.252 1.252 0 0 0-.289.43 1.549 1.549 0 0 0-.098.586v.16H7.11zm.863 2.563a.992.992 0 0 1-.723-.297.965.965 0 0 1-.293-.718.944.944 0 0 1 .293-.707.992.992 0 0 1 .723-.297c.268 0 .504.099.707.297a.959.959 0 0 1 .16 1.218 1.067 1.067 0 0 1-.371.368.943.943 0 0 1-.496.136z" fill="#696974"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z"></path></clipPath></defs></svg>
	);
}

function Settings() {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="settings-icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.6 1.157C5.6.518 6.118 0 6.757 0h.486C7.883 0 8.4.518 8.4 1.157v.068c0 1.006 1.216 1.51 1.926.798.441-.44 1.156-.44 1.596 0l.054.054c.441.44.441 1.155 0 1.596-.71.711-.207 1.927.798 1.927h.069c.639 0 1.157.518 1.157 1.157v.486c0 .639-.518 1.157-1.157 1.157h-.069c-1.005 0-1.509 1.216-.798 1.926.441.441.441 1.156 0 1.596l-.054.054c-.44.441-1.155.441-1.596 0-.71-.71-1.926-.207-1.926.798v.07C8.4 13.481 7.882 14 7.243 14h-.486c-.639 0-1.157-.518-1.157-1.157v-.069c0-1.005-1.216-1.509-1.927-.798-.44.441-1.155.441-1.596 0l-.054-.054a1.129 1.129 0 0 1 0-1.596c.711-.71.208-1.926-.798-1.926h-.068A1.157 1.157 0 0 1 0 7.243v-.486C0 6.118.518 5.6 1.157 5.6h.068c1.006 0 1.51-1.216.798-1.927a1.129 1.129 0 0 1 0-1.596l.054-.054c.44-.44 1.155-.44 1.596 0 .711.711 1.927.208 1.927-.798v-.068zM7 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" fill="#696974"></path></svg>
	);
}

function Plus() {
	return (
		<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="#303036" stroke-width="2" fill="none" fill-rule="evenodd" opacity=".9" stroke-linecap="round" stroke-linejoin="round"><path d="M7 1v12M1 7h12"></path></g></svg>
	);
}

function Goals() {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_8248_212640)"><circle cx="8" cy="8" r="7" stroke="#696974" stroke-width="2"></circle><path d="M11.35 4.976L9.55 9.414a.25.25 0 0 1-.138.138L4.975 11.35a.25.25 0 0 1-.326-.325l1.799-4.439a.25.25 0 0 1 .138-.138l4.438-1.798a.25.25 0 0 1 .326.326z" stroke="#696974" stroke-width="1.5"></path><circle cx="8" cy="8" r=".5" fill="#696974"></circle></g></svg>
	);
}

function Evidences() {
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 2H5a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" stroke="#696974" stroke-width="2"></path><path d="M6 5h3M6 8h2M6 11h2" stroke="#696974" stroke-width="2" stroke-linecap="round"></path></svg>
	);
}

function Checkmark({ checked }) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g stroke="#323232" stroke-width="2">
				<circle cx="12" cy="12" r="11" fill={checked ? "#FFE013" : ""}></circle>
				<path d="M15.778 9.828l-4.95 4.95-1.829-1.828" stroke-linecap="round"></path>
			</g>
		</svg>
	);
}

function Date() {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 3a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H4zm0 4a1 1 0 0 0-1 1v3.5A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5V8a1 1 0 0 0-1-1H4z" fill="#696974"></path><path d="M4 2a1 1 0 0 1 2 0v1H4V2zM10 2a1 1 0 1 1 2 0v1h-2V2z" fill="#696974"></path></svg>
	);
}

function SortArrow({ direction = "asc" }) {
	return (
		<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.5 0L25 25H0L12.5 0Z" fill="#ffe013" transform={direction === "desc" ? "rotate(180 12.5 12.5)" : ""} />
		</svg>
	);
}

function Person() {
	return (
		<svg width="25" height="25" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#696974"><path d="M512 597.994667q108.010667 0 225.002667 46.997333t116.992 123.008l0 85.994667-684.010667 0 0-85.994667q0-76.010667 116.992-123.008t225.002667-46.997333zM512 512q-69.994667 0-120-50.005333t-50.005333-120 50.005333-121.002667 120-51.008 120 51.008 50.005333 121.002667-50.005333 120-120 50.005333z" /></svg>
	);
}

function HamburgerMenu() {
	return (
		<svg width="200" height="200" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<circle id="Oval" fill="#FFE013" cx="50%" cy="50%" r="50%"></circle>
			<line x1="25%" y1="30%" x2="75%" y2="30%" stroke="#404040" stroke-width="10" stroke-linecap="round"></line>
			<line x1="25%" y1="50%" x2="75%" y2="50%" stroke="#404040" stroke-width="10" stroke-linecap="round"></line>
			<line x1="25%" y1="70%" x2="75%" y2="70%" stroke="#404040" stroke-width="10" stroke-linecap="round"></line>
		</svg>
	);
}

export default {
	Requests,
	Collections,
	Help,
	Settings,
	Plus,
	Goals,
	Evidences,
	Checkmark,
	Date,
	SortArrow,
	Person,
	HamburgerMenu
};