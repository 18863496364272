import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import Icons from "./assets/icons/icons.js";
import Collections from "./pages/collections.js";
import Aanvragen from "./pages/ontvangen-aanvragen.js";
import Sidebar from "./components/sidebar.js";
import logoWide from "./assets/img/logo-wide.svg";

function App() {
    return (
        <Router>
            <Sidebar />
            <div id="content">
                <div className="mobile-top-bar">
                    <button onClick={() => document.getElementById("sidebar").classList.toggle("open")}><Icons.HamburgerMenu /></button>
                    <img src={logoWide} className="logo-wide" alt="logo" />
                </div>
                <Routes>
                    <Route index path="/" element={<Collections />} />
                    <Route path="/aanvragen" element={<Aanvragen />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
